import React,{useEffect} from "react";
import Text from "../../components/data-display/text";
//import AppleStore from "../../images/apple-appstore.svg";
import { Check } from "react-feather";
import SEO from "../../components/seo/seo";
import '../../components/gallery-components/index.css';
import App from '../../components/gallery-components/App';
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
//import { OpenRoute } from "../../components/app-routes/app-routes";
//import AppStore from "../../images/google_playstore.svg";
import SydeAboutSection from './SydeAboutSection';
import QuickTaskAboutSection from "./QuickTaskAboutSection";
import { isQuickTaskProject } from "../../utils/constant.urls";


const styles={
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    margin: "0 auto",
    marginTop: "90px",
    marginBottom: "75px"
  },
  badges: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly"
  },
  bigSpacing: {
    height: "30px"
  },
  smallSpacing: {
    height: "15px"
  },
  cover: {
    objectFit: 'cover',
  }
}
const AboutUs = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
          <SEO title="About us" pathname="/about-us"/>
    <div style={{ background: "white" }}>
    <div
      style={{
        position: "relative",
        paddingTop: "150px",
        paddingBottom: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      data-scroll={true}
    >
      <Container
        maxWidth={"xl"}
        style={{ padding: "0 12%" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid item xs={12} sm={12} style={{}}>
            <div style={{ paddingBottom: "30px", textAlign: "center", verticalAlign: "middle", marginBottom: !xsDown ? "60px" : "" }}>
              <Text
                variant={"h1"}
                black       
              >
                About Us
                  </Text>     
                  {isQuickTaskProject ?  <Text
                variant={"h6"}
                //black
             
              >
               Fostering connections between individuals prepared to work and you
                  </Text> : ""}
             
            </div>
          </Grid>
              {isQuickTaskProject?<QuickTaskAboutSection /> : <SydeAboutSection />}
        </div>
        {/* <div style={{ height: "64px" }} /> */}
      </Container>
    </div>
    <div style={{marginTop:"100px"}}>
         {!isQuickTaskProject &&<App />}

    </div>
    <div style={{ height: "64px" }}></div>
  </div>
  </>
  );
};

export default AboutUs;