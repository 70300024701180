import React from "react";
import "./App.css";
import { Carousel } from "./lib";
import img1 from "../../assets/images/image1.png";


function App() {
  const data = [
    {
      image:
        "https://ik.imagekit.io/wffo0jsx3/o/Artboard%20%E2%80%93%203.png?alt=media&token=360efb25-9751-4741-b3c1-701056e440a5"     
    },
    {
      image:
        "https://ik.imagekit.io/wffo0jsx3/o/image2.png?alt=media&token=a32baecc-3d60-42bf-9f58-eac7b3578244",
    },
    {
      image:
        "https://ik.imagekit.io/wffo0jsx3/o/image3.png?alt=media&token=c2c13fb0-c857-4faf-b51f-54fefefedae5",
    },
    {
      image:
        "https://ik.imagekit.io/wffo0jsx3/o/image4.png?alt=media&token=23e4078c-d5c6-4bae-9509-0d41a11c4bd9",
    },
    {
      image:
        "https://ik.imagekit.io/wffo0jsx3/o/image5.png?alt=media&token=4f8287f8-ddef-4c96-b63b-7def74809caf",
    },
    {
    image:
        "https://ik.imagekit.io/wffo0jsx3/o/images%2Fbath2.png?alt=media&token=cf50e0d5-a1cb-4ba3-8161-be800e5cbe4c",
    },
    {
      image:"https://ik.imagekit.io/wffo0jsx3/o/images%2Fworker.jpg?alt=media&token=5eb644a8-1a77-4437-a10a-4c441f42b7b5"
    }
    
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            padding: "0 20px",
          }}
        >
          <Carousel
            data={data}
            time={2000}
            width="800px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "850px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;